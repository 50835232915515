/* Overlay */

@keyframes fadeIn1 {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

#overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	display: none;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

#overlayBg {
	position: absolute;
	width: 100%;
	height: 100%;
}

#overlayWrapper {
	position: relative;
	margin: auto;
	padding: 8px;
	width: 100%;
	max-width: 360px;
}

#overlayWindow {
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 32px;
	padding: 16px;
	padding-bottom: 32px;
	animation: fadeIn1 ease-in .25s forwards;
	position: relative;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

button#overlayClose {
	width: 32px;
	height: 32px;
	position: absolute;
	right: 16px;
	top: 16px;
}

button#overlayClose img {
	width: 16px;
	height: 16px;
}

#overlayWindow h2 {
	color: #000;
	font-size: 48px;
	padding: 32px;
	padding-bottom: 16px;
	text-align: center;
	line-height: 1;
	letter-spacing: -2px;
}

p#overlayMessage {
	color: #000;
	font-size: 18px;
	text-align: center;
	display: none;
	line-height: 1.35;
}

#overlayContent button {
	width: 100%;
	background-color: #000;
	color: #FFF;
	margin-top: 16px;
}

#overlayContent button.disabled {
	opacity: 0.5;
	pointer-events: none;
}

#overlayContent button:active {
	color: #000;
}

#overlayContent button img {
	height: 28px;
	margin-right: 8px;
}

/* Connect */

#overlayConnect p {
	color: var(--df-red);
	text-align: center;
	margin-top: 16px;
}

/* Submit */

#overlaySubmit button {
	background-color: var(--df-orange);
}

#overlaySubmit p {
	color: var(--df-red);
	text-align: center;
	margin-top: 16px;
	font-size: 16px;
}

/* Submitted */

#overlaySubmitted {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	position: relative;
}

#overlaySubmitted img {
	width: 200px;
}