/* Wardrobe */

@media screen and (orientation: portrait) {
  #sectionWardrobe {
    min-height: calc(100vw + 16px + 196px);
  }
}

/* Stage */

#wardrobeStage {
  height: 100%;
  aspect-ratio: 4/4;
  position: relative;
}

#wardrobeStage button.iconButton {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

#wardrobeStage button#stageReset {
  top: 16px;
  left: 16px;
}

#wardrobeStage button#stageReset img {
  padding-left: 2px;
}

#wardrobeStage button#stageDownload {
  left: 16px;
  bottom: 16px;
}

#wardrobeStage button#stageDownload img {
  padding-bottom: 4px;
}

#wardrobeStage button.bigButton {
  position: absolute;
  right: 16px;
  bottom: 16px;
  background-color: var(--df-yellow);
  padding-left: 20px;
  padding-right: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

#wardrobeStage button.bigButton svg {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

@media (hover: hover) {
  #wardrobeStage button.bigButton:hover {
    background-color: #FFF !important;
  }
}

#wardrobeStage button.bigButton:active {
  background-color: var(--df-yellow) !important;
}

#wardrobeStage button.bigButton:active svg {
  fill: #FFF;
}

@media only screen and (max-width: 1023px) {
  #wardrobeStage button.iconButton {
    width: 40px;
    height: 40px;
  }

  #wardrobeStage button#stageReset {
    left: 8px;
    top: 8px;
  }

  #wardrobeStage button#stageDownload {
    left: 8px;
    bottom: 8px;
  }

  #wardrobeStage button#stageDownload img {
    width: 24px;
    height: 24px;
  }

  #wardrobeStage button.bigButton {
    right: 8px;
    bottom: 8px;
    height: 40px;
    font-size: 16px;
    padding-right: 8px;
    padding-left: 16px;
  }

  #wardrobeStage button.bigButton svg {
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }
}

@media screen and (orientation: portrait) {
  #wardrobeStage {
    width: 100%;
    height: auto;
  }
}

/* Viewer */

@keyframes fadeInOut3 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#wardrobeViewer {
  height: 100%;
  margin-left: 24px;
  width: 552px;
  position: relative;
  min-width: 200px;
}

#viewerMenu button {
  float: left;
}

button#menuHome {
  margin-left: 8px;
  margin-right: 12px;
}

select#menuCategories {
  float: right;
  margin-right: 16px;
  width: calc(100% - 144px);
}

#viewer {
  width: 100%;
  height: calc(100% - 56px);
  margin-top: 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

#viewer::-webkit-scrollbar {
  display: none;
}

button.viewerUpDown {
  position: absolute;
  left: 50%;
  margin-left: -24px;
  cursor: var(--df-cursor), auto;
  visibility: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

button.viewerUpDown img {
  padding-top: 6px;
}

button#viewerUp {
  top: 52px;
  transform: rotate(-180deg);
}

button#viewerDown {
  margin-top: -36px;
}

p#viewerMessage {
  background-color: rgba(0, 0, 0, 0.5);
  line-height: 1;
  position: absolute;
  left: -8px;
  right: 0;
  top: 56px;
  bottom: 0;
  width: max-content;
  height: max-content;
  margin: auto;
  border-radius: 999px;
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  display: none;
  animation: fadeInOut3 ease-in 2.5s forwards;
}

@media only screen and (max-width: 1279px) {
  #wardrobeViewer {
    width: 368px;
  }
}

@media only screen and (max-width: 1023px) {
  #wardrobeViewer {
    margin-left: 8px;
    width: 288px;
  }

  button#menuHome {
    margin-right: 8px;
  }

  select#menuCategories {
    width: calc(100% - 132px);
    margin-right: 12px;
  }

  p#viewerMessage {
    left: -4px;
  }
}

@media screen and (orientation: portrait) {
  #wardrobeViewer {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
    height: 196px;
  }

  button#menuHome {
    margin-left: 0;
  }

  select#menuCategories {
    float: right;
    margin-right: 0;
    width: calc(100% - 112px);
  }

  #viewer {
    white-space: nowrap;
    position: absolute;
    padding-left: 8px;
    padding-right: 4px;
    width: calc(100% + 20px);
    left: -16px;
    height: 140px;
    overflow-y: hidden;
  }

  button.viewerUpDown {
    display: none;
  }

  p#viewerMessage {
    left: 0;
  }
}