/* Avatar */

.avatar {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.avatar img {
  width: 100%;
  height: 100%;
  position: absolute;
}