/* Layout */

.clear {
  clear: both;
}

button.bigButton {
  color: #000;
  background-color: #FFF;
  font-size: 18px;
  border-radius: 999px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  transition: background-color 0.15s linear;
}

@media (hover: hover) {
  button.bigButton:hover {
    background-color: var(--df-yellow) !important;
  }
}

button.bigButton:active {
  color: #FFF;
  background-color: var(--df-yellow);
  transition: none;
}

button.iconButton {
  background-color: #000;
  border-radius: 999px;
  width: 48px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s linear;
}

@media (hover: hover) {
  button.iconButton:not(.selected):hover {
    background-color: var(--df-yellow) !important;
  }
}

button.iconButton:active {
  background-color: var(--df-yellow);
  transition: none;
}

button.iconButton * {
  width: 28px;
  height: 28px;
  fill: #FFF;
}

button.iconButton:active * {
  filter: invert(1);
  fill: #000;
}

button.iconButton.selected * {
  fill: var(--df-yellow);
}

button.iconButton.selected:active * {
  fill: #000;
}

.section {
  display: flex;
  width: 100%;
  height: calc(100% - 96px);
  justify-content: center;
  position: absolute;
  padding: 32px;
  top: 96px;
  box-sizing: border-box;
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1023px) {
  .section {
    padding: 16px;
    height: calc(100% - 72px);
    top: 72px;
  }
}

@media screen and (orientation: portrait) {
  .section {
    flex-direction: column;
  }
}

/* Header */

header {
  position: fixed;
  width: 100%;
}

h1 img {
  width: 258px;
  height: 64px;
  margin: 32px;
}

#headerDashboard {
  background-color: #000;
  border-radius: 999px;
  width: 360px;
  height: 64px;
  position: absolute;
  left: 50%;
  margin-left: -180px;
  top: 32px;
}

#dashboardXP {
  float: left;
}

#xpIcon {
  background-color: #FFF;
  padding: 6px;
  padding-top: 8px;
  padding-bottom: 4px;
  border-radius: 999px;
  margin: 12px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: -4px;
}

#xpIcon img {
  height: 28px;
  width: 28px;
}

#dashboardXP p {
  display: inline-block;
  line-height: 1;
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  font-size: 30px;
}

#dashboardXP p span {
  font-size: 18px;
  margin-left: 6px;
  color: var(--df-yellow);
}

#headerDashboard button {
  float: right;
  margin: 12px;
  margin-left: 0;
  height: 40px;
}

nav {
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 32px;
  padding-right: 32px;
  box-sizing: border-box;
}

nav a {
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  font-size: 24px;
  margin-left: 16px;
  margin-top: 20px;
  line-height: 1;
  display: inline-block;
  transition: opacity 0.15s linear;
  opacity: .75;
}

a#nav-home {
  display: none;
}

nav button.bigButton {
  display: none;
  margin-top: 16px;
  margin-left: 16px;
}

nav button.iconButton {
  width: 40px;
  height: 40px;
  vertical-align: -3px;
  margin-left: 16px;
  display: none;
}

nav button.iconButton img {
  width: 24px;
  height: 24px;
  padding-top: 2px;
}

@media (hover: hover) {
  nav a:hover {
    opacity: 1;
  }
}

button#navBurger {
  position: absolute;
  right: 0;
  top: 12px;
  padding: 16px;
  padding-top: 17px;
  padding-bottom: 17px;
  display: none;
}

#burger {
  width: 32px;
  height: 18px;
  border-top: 6px solid #FFF;
  border-bottom: 6px solid #FFF;
}

#burger.selected {
  border-color: var(--df-orange);
}

#burger:after {
  content: "";
  background-color: #FFF;
  width: 100%;
  height: 6px;
  display: block;
  margin-top: 6px;
}

#burger.selected:after {
  background-color: var(--df-orange);
}

@media only screen and (max-width: 1279px) {
  h1 {
    display: none;
  }

  #headerDashboard {
    left: 32px;
    margin-left: 0;
  }

  a#nav-home {
    display: inline-block;
  }
}

@media only screen and (max-width: 1023px) {
  #headerDashboard button {
    display: none;
  }

  #headerDashboard {
    left: 16px;
    top: 16px;
    width: max-content;
    height: 56px;
    padding-right: 20px;
  }

  #xpIcon {
    margin: 8px;
  }

  nav {
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: right 0.25s linear;
    padding-top: 59px;
    padding-right: 0;
  }

  nav a {
    font-size: 30px;
    margin-top: 16px;
    display: block;
    width: max-content;
    color: var(--df-orange);
  }

  nav button.bigButton {
    display: block;
  }

  nav button.iconButton {
    background-color: var(--df-orange);
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  button#navBurger {
    display: inline;
  }
}