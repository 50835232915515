/* FAQ */

#sectionFAQ {
  display: inline;
  height: auto;
  text-align: center;
}

#sectionFAQ h2 {
  font-size: 48px;
}

#sectionFAQ h3 {
  font-size: 24px;
  max-width: 640px;
  margin: auto;
  margin-top: 24px;
}

#sectionFAQ p {
  max-width: 640px;
  margin: auto;
  margin-top: 12px;
  font-size: 16px;
}

#sectionFAQ p a {
  text-decoration: underline;
}