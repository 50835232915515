/* ViewerItem */

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn05 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.viewerItem {
  width: calc(33.3% - 24px);
  aspect-ratio: 4/4;
  background-color: rgba(255, 255, 255, 0.9);
  float: left;
  border-radius: 32px;
  position: relative;
  margin: 8px;
  margin-top: 16px;
  margin-right: 16px;
  cursor: var(--df-cursor), auto;
}

.viewerItem.unavailable {
  cursor: auto;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (hover: hover) {
  .viewerItem:not(.unavailable):hover:after {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    border-radius: 40px;
    border: solid 8px #000;
  }
}

.viewerItem.selected:after {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  bottom: -8px;
  right: -8px;
  border-radius: 40px;
  border: solid 8px #000;
}

.viewerItem img.itemImage,
.viewerItem img.itemKey {
  height: 75%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  opacity: 0;
}

.viewerItem img.itemKey {
  opacity: 1;
}

.viewerItem.loaded img.itemImage {
  animation: fadeIn1 ease-in .25s forwards;
}

.viewerItem.unavailable.loaded img.itemImage {
  animation: fadeIn05 ease-in .25s forwards;
}

.viewerItem.category img.itemImage {
  height: 80%;
}

.viewerItem.background img.itemImage {
  height: 100%;
  border-radius: 32px;
}

.viewerItem hgroup {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: normal;
}

.viewerItem hgroup * {
  line-height: 1;
}

.viewerItem.background hgroup * {
  text-shadow: 1px 1px #000;
}

.viewerItem h3 {
  font-size: 16px;
  color: #000;
}

.viewerItem.background h3 {
  color: #FFF;
}

.viewerItem.unavailable h3 {
  opacity: 0.5;
}

.viewerItem.category h3 {
  font-size: 18px;
  padding-bottom: 4px;
}

.viewerItem h4 {
  font-size: 12px;
  margin-top: 4px;
  color: var(--df-red);
}

.viewerItem h4.buy,
.viewerItem h4.unlock {
  display: inline-block;
  cursor: var(--df-cursor), auto;
  background-color: var(--df-green);
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 999px;
  color: #FFF;
  text-shadow: none;
}

.viewerItem h4.unlock {
  background-color: var(--df-yellow);
  color: #000;
}

.viewerItem h4.unlocked {
  color: var(--df-yellow);
}

.viewerItem h4.locked {
  color: var(--df-orange);
}

.viewerItem.background h4.buy,
.viewerItem.background h4.unlock {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 1279px) {
  .viewerItem {
    width: calc(50% - 24px);
  }
}

@media only screen and (max-width: 1023px) {
  .viewerItem {
    border-radius: 28px;
    margin-top: 12px;
    margin-right: 12px;
    width: calc(50% - 20px);
  }

  @media (hover: hover) {
    .viewerItem:hover:after {
      border-radius: 36px;
    }
  }

  .viewerItem img.itemImage,
  .viewerItem img.itemKey {
    height: 75%;
  }

  .viewerItem.background img.itemImage {
    border-radius: 28px;
  }

  .viewerItem hgroup {
    padding: 6px;
  }

  .viewerItem h3 {
    font-size: 14px;
  }

  .viewerItem.category h3 {
    font-size: 16px;
  }

  .viewerItem h4 {
    font-size: 10px;
    margin-top: 2px;
  }

  .viewerItem h4.buy,
  .viewerItem h4.unlock {
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media screen and (orientation: portrait) {
  .viewerItem {
    width: auto;
    height: calc(100% - 16px);
    float: none !important;
    display: inline-block;
    margin-right: 12px;
    margin-top: 8px;
  }
}