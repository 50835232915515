/* Fonts */

@font-face {
  font-family: "itc-avant-garde-gothic-lt-bold";
  src: url("assets/fonts/itc-avant-garde-gothic-lt-bold.woff2") format("woff2");
}

@font-face {
  font-family: "AvantGarde-Book";
  src: url("assets/fonts/AvantGarde-Book.woff2") format("woff2");
}

/* Vars */

:root {
  --df-orange: #F60;
  --df-yellow: #FC0;
  --df-red: #cb171e;
  --df-green: #679933;
  --df-pink: #FF999A;
  --df-deep-red: #A60707;
  --df-deep-yellow: #F1B302;
  --df-blue: #6799CC;
  --df-purple: #CD9BCD;
  --df-dark-green: #146735;
  --df-silver: #C0C0C0;
  --df-bronze: #CD7F32;
  --df-magenta: #BB4E9F;
  --df-cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23000000" width="64" height="64" viewBox="0 0 59.75 64"><path d="M57.36,38.71l-10.12-18.82c-1.62-2.95-5.32-4.02-8.26-2.41-2.16,1.19-3.4,3.57-3.12,6.02l-1.32-2.42c-1.62-2.95-5.32-4.02-8.26-2.41-1.63,.89-2.76,2.48-3.07,4.31L11.41,3.14C9.78,.2,6.08-.87,3.14,.76,.2,2.39-.87,6.1,.76,9.04c.06,.11,.12,.22,.19,.32l17.86,30.06c-.7,2.71-1.31,5.16-1.53,6.24-1.11,5.33,1.33,11.95,8.29,14.83,6.15,2.71,11.94,3.86,17.21,3.42l.25-.02c5.36-.51,9.97-2.75,13-6.33,4.45-5.26,4.93-12.53,1.33-18.85Z"/></svg>');
}

/* Set-up */

html {
  background-color: var(--df-orange);
}

body {
  font-family: "AvantGarde-Book", sans-serif;
  font-size: 14px;
  color: #FFF;
  line-height: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  overflow-anchor: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  margin: 0;
  line-height: 1.25;
  font-weight: normal;
}

p {
  margin: 0;
  line-height: 1.5;
  overflow-wrap: break-word;
}

a {
  color: #FFF;
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: var(--df-cursor), auto;
  padding: 0;
  outline: none;
  line-height: 1;
}

/* Form */

select {
  -webkit-appearance: none;
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  font-size: 18px;
  outline: none;
  height: 48px;
  border: none;
  border-radius: 999px;
  background-color: #000;
  color: #FFF;
  padding-left: 20px;
  padding-right: 48px;
  background-image: url("assets/img/icon-arrow-down.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: top 14px right 16px;
  cursor: var(--df-cursor), auto;
}

/* WalletConnect modal fixes */

#walletconnect-wrapper {
  line-height: normal;
}

#walletconnect-wrapper * {
  box-sizing: border-box;
}

#walletconnect-wrapper a {
  color: #000;
}

@media screen and (orientation: portrait) {
  .walletconnect-modal__footer  {
    overflow: auto;
    justify-content: left !important;
  }
}