/* Events */

#sectionEvents {
  display: inline;
  height: auto;
  padding: 0;
  padding-top: 32px;
}

@media only screen and (max-width: 1023px) {
  #sectionEvents {
    padding-top: 16px;
  }
}

/* Group */

.eventGroup {
  padding: 32px;
  padding-right: 0;
  width: 100%;
  box-sizing: border-box;
}

.eventGroup h2 {
  color: #000;
  font-size: 72px;
  letter-spacing: -2px;
  line-height: 1;
}

.groupEvents {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: inline-flex;
  scrollbar-width: none;
  height: 435px;
}

.groupEvents::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .eventGroup {
    padding: 16px;
    padding-right: 0;
  }

  .eventGroup h2 {
    font-size: 48px;
  }

  .groupEvents {
    height: 351px;
  }
}

/* Event */

.event {
  float: left;
  height: calc(100% - 24px);
  margin-top: 24px;
  flex-shrink: 0;
  padding-right: 32px;
}

.event.upcoming {
  opacity: 0.5;
  pointer-events: none;
}

.eventInfo {
  background-color: #000;
  height: 100%;
  width: 280px;
  float: left;
  box-sizing: border-box;
  padding: 16px;
  position: relative;
}

.eventInfo span {
  line-height: 1;
  font-size: 18px;
  display: block;
  padding-bottom: 4px;
}

.eventInfo h3 {
  font-size: 36px;
  color: var(--df-yellow);
  line-height: 1;
  padding-bottom: 4px;
}

p.infoDescription,
.eventInfo h4 {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--df-pink);
  margin-bottom: 8px;
}

p.infoDescription {
  font-size: 12px;
  line-height: 1.25;
}

.eventInfo h4 {
  font-size: 24px;
  color: #FFF;
  line-height: 1;
}

.infoPrizes {
  height: 76px;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  display: inline-flex;
}

.infoPrizes::-webkit-scrollbar {
  display: none;
}

.infoPrize {
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  border-radius: 999px;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-right: 8px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  font-size: 36px;
  background-color: #FFF;
  color: #000;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
}

.infoPrize img {
  width: 100%;
}

.infoPrizeXP {
  cursor: auto;
}

.infoPrizeXP span {
  font-size: 12px;
  line-height: 0.75;
}

.infoPrizeXP p {
  font-size: 18px;
  line-height: 1;
  font-family: "AvantGarde-Book", sans-serif;
}

.infoPrizeXP p:after {
  content: "XP";
  margin-left: 2px;
  font-size: 12px;
}

.eventCTA * {
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
}

button.eventButton {
  color: #FFF;
}

button.eventButton:active {
  color: #000;
}

img.eventImage {
  height: 100%;
  float: left;
  opacity: 0;
  aspect-ratio: 5/6;
}

img.eventImage.loaded {
  animation: fadeIn1 ease-in .25s forwards;
}

@media only screen and (max-width: 1023px) {
  .event {
    height: calc(100% - 16px);
    padding-right: 16px;
    margin-top: 16px;
  }

  .eventInfo {
    width: 220px;
    padding: 12px;
  }

  .eventInfo span {
    font-size: 14px;
    padding-bottom: 2px;
  }

  .eventInfo h3 {
    font-size: 30px;
    padding-bottom: 2px;
  }

  p.infoDescription,
  .eventInfo h4 {
    padding-bottom: 6px;
    margin-bottom: 6px;
  }

  .eventInfo h4 {
    font-size: 18px;
  }

  .infoPrizes {
    height: 56px;
  }

  .infoPrize {
    width: 48px;
    height: 48px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 6px;
    font-size: 30px;
  }

  .infoPrizeXP span {
    font-size: 10px;
    line-height: 0.75;
  }

  .infoPrizeXP p {
    font-size: 14px;
  }

  .infoPrizeXP p:after {
    font-size: 10px;
  }

  .eventCTA * {
    bottom: 12px;
    width: calc(100% - 24px);
  }
}