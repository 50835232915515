/* Results */

#sectionResults {
  display: inline;
  height: auto;
}

#sectionResults h2,
#sectionResults h3 {
  font-size: 48px;
  line-height: 1;
}

#sectionResults h2 {
  font-family: "AvantGarde-Book", sans-serif;
}

#results {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-top: 24px;
}

.resultsAvatar {
  width: 100%;
  aspect-ratio: 4/4;
  position: relative;
}

#resultsAvatar0 {
  grid-area: 1 / 1 / span 2 / span 2;
}

.resultsAvatar p {
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 999px;
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 1;
}

@media screen and (orientation: portrait) {
  #sectionResults h2,
  #sectionResults h3 {
    font-size: 24px;
    text-align: center;
  }

  #results {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 12px;
  }

  #resultsAvatar0 {
    grid-area: auto;
  }

  .resultsAvatar p {
    top: 8px;
    left: 8px;
  }
}