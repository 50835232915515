/* Vote */

@keyframes scaleIn {
  0% {
    transform: rotate(180deg) scale(0);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes scaleInOut {
  0% {
    transform: rotate(180deg) scale(0);
  }

  25% {
    transform: rotate(0deg) scale(1);
  }

  75% {
    transform: rotate(0deg) scale(1);
  }

  100% {
    transform: rotate(-180deg) scale(0);
  }
}

@keyframes fadeOut1 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#sectionVote {
  flex-direction: column;
  align-items: center;
  min-height: 400px;
}

#sectionVote h2,
#sectionVote h3 {
  font-size: 48px;
  line-height: 1;
  text-align: center;
}

#sectionVote h2 {
  font-family: "AvantGarde-Book", sans-serif;
}

#voteEntries {
  height: calc(100% - 96px - 16px - 64px);
  margin-top: 16px;
  aspect-ratio: 2/1;
  margin-bottom: 64px;
  position: relative;
}

span#entriesVersus {
  border-radius: 999px;
  background-color: #000;
  font-size: 48px;
  position: absolute;
  width: 96px;
  padding-top: 4px;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: "itc-avant-garde-gothic-lt-bold", sans-serif;
  transform: scale(0);
}

span#entriesVersus.in {
  animation: scaleIn ease-out .25s forwards;
}

#voteFinished {
  display: none;
  justify-content: center;
  align-items: center;
  height: calc(100% - 96px - 16px);
  margin-top: 16px;
  flex-direction: column;
  position: relative;
}

#voteFinished h4,
#voteFinished h5 {
  line-height: 1;
  font-size: 36px;
  text-align: center;
}

#voteFinished h5 {
  font-family: "AvantGarde-Book", sans-serif;
  margin-top: 4px;
}

#voteFinished img {
  width: 240px;
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  span#entriesVersus {
    font-size: 30px;
    width: 56px;
    height: 52px;
  }

  #voteFinished img {
    width: 160px;
  }
}

@media only screen and (max-width: 1023px) {
  #sectionVote h2,
  #sectionVote h3 {
    font-size: 24px;
  }

  #voteEntries {
    height: calc(100% - 48px - 16px - 64px);
  }

  #voteFinished h4,
  #voteFinished h5 {
    font-size: 30px;
  }

  #voteFinished h5 {
    font-size: 24px;
  }
}

@media (max-aspect-ratio: 3/2) {
  #voteEntries {
    height: calc(100% - 48px - 16px);
    aspect-ratio: 1/2;
    margin-bottom: 0;
  }

  span#entriesVersus.in {
    animation: scaleInOut ease-in-out 1s forwards;
  }

  #voteFinished {
    height: calc(100% - 48px - 16px);
  }
}

/* Entry */

.voteEntry {
  height: 100%;
  float: left;
  aspect-ratio: 4/4;
}

.voteEntry.out {
  animation: fadeOut1 ease-out .25s forwards;
  pointer-events: none;
}

.voteEntry.winner {
  animation-delay: .5s;
}

.voteEntry button {
  display: block;
  margin: auto;
  margin-top: 16px;
  width: 112px;
}

@media (max-aspect-ratio: 3/2) {
  .voteEntry {
    height: 50%;
    float: none;
  }

  .voteEntry button {
    display: none;
  }
}